import React from "react";
import PropTypes from "prop-types";
import RelatedItem from "./";
import ListItemTitle from "../../elements/list-item-title";
import EventTypeCapsLabel from "../../elements/caps-label/event-type";
import DatesCapsLabel from "../../elements/caps-label/dates";
import EventLocationLabel from "../../elements/event-location-label";

const RelatedEvent = ({
  title,
  location,
  dateStart,
  dateEnd,
  eventType,
  link,
}) => {
  return (
    link && (
      <RelatedItem link={link} className="c-related-event">
        {dateStart && <DatesCapsLabel start={dateStart} end={dateEnd} />}
        {title && <ListItemTitle text={title} />}
        {eventType && <EventTypeCapsLabel text={eventType} />}
        {location && <EventLocationLabel location={location} />}
      </RelatedItem>
    )
  );
};

export default RelatedEvent;

export const RelatedEventPropTypes = {
  title: PropTypes.string,
  dateStart: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),
  location: PropTypes.string,
  eventType: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(["news", "event", "page"]),
};

RelatedEvent.propTypes = RelatedEventPropTypes;
