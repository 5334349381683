import React from "react";
import PropTypes from "prop-types";

const ListItemTitle = ({ text }) =>
  text && <h5 className="e-list-item-title">{text}</h5>;

export default ListItemTitle;

export const ListItemTitlePropTypes = {
  text: PropTypes.string,
};

ListItemTitle.propTypes = ListItemTitlePropTypes;
