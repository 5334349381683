import React from "react";
import PropTypes from "prop-types";
import RelatedItem from "./";
import ListItemTitle from "../../elements/list-item-title";

const RelatedPage = ({ title, description, link }) => {
  return (
    link && (
      <RelatedItem link={link} className="c-related-page">
        {title && <ListItemTitle text={title} />}
        {description && (
          <p className="c-related-page-description body-small">{description}</p>
        )}
      </RelatedItem>
    )
  );
};

export default RelatedPage;

export const RelatedPagePropTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(["news", "event", "page"]),
};

RelatedPage.propTypes = RelatedPagePropTypes;
