import React from "react";
import PropTypes from "prop-types";
import RelatedItem from "./";
import ListItemTitle from "../../elements/list-item-title";
import TopicCapsLabel from "../../elements/caps-label/topic";
import MediaTypeCapsLabel from "../../elements/caps-label/media-type";

const RelatedNews = ({ title, mediaType, topic, link }) => {
  return (
    link && (
      <RelatedItem link={link} className="c-related-news">
        {title && <ListItemTitle text={title} />}
        {mediaType && <MediaTypeCapsLabel text={mediaType} />}
        {topic && <TopicCapsLabel text={topic} />}
      </RelatedItem>
    )
  );
};

export default RelatedNews;

export const RelatedNewsPropTypes = {
  title: PropTypes.string,
  mediaType: PropTypes.string,
  topic: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(["news", "event", "page"]),
};

RelatedNews.propTypes = RelatedNewsPropTypes;
