import React from "react";
import PropTypes from "prop-types";

const RelatedItem = ({ link, className = "", children }) =>
  link && (
    <a href={link} className={`c-related-item${className && " " + className}`}>
      <div className="c-related-item-inner">{children}</div>
    </a>
  );

export default RelatedItem;

export const RelatedItemPropTypes = {
  link: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

RelatedItem.propTypes = RelatedItemPropTypes;
