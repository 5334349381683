import React from "react";
import RelatedEvent, {
  RelatedEventPropTypes,
} from "../components/related-item/related-event";
import RelatedNews, {
  RelatedNewsPropTypes,
} from "../components/related-item/related-news";
import RelatedPage, {
  RelatedPagePropTypes,
} from "../components/related-item/related-page";
import PropTypes from "prop-types";

const RelatedInner = ({ item }) => {
  if (item._type === "article" || item._type === "policySubmission") {
    return (
      <RelatedNews
        {...item}
        link={item?.path || item?.link}
        mediaType={item?.articleType || item?.mediaType}
        topic={item?.taxonomy?.topic?.title || item?.topic}
      />
    );
  } else if (item._type === "event") {
    return (
      <RelatedEvent
        {...item}
        dateStart={new Date(item.dateStart)}
        dateEnd={new Date(item.dateEnd)}
        link={item?.path || item?.link}
      />
    );
  } else if (item._type === "page") {
    return (
      <RelatedPage
        {...item}
        description={item?.excerpt || item?.description}
        link={item?.path || item?.link}
      />
    );
  } else {
    return "";
  }
};

RelatedInner.propTypes = {
  item: PropTypes.object,
};

const Related = ({ title = "See more", related }) =>
  related &&
  related?.[0] && (
    <div className="m-related">
      <div className="m-related-inner inner">
        {title && <h2 className="m-related-title h4">{title}</h2>}
        <div className="m-related-wrapper">
          {related.map((item, i) => {
            return item && item._type && <RelatedInner item={item} key={i} />;
          })}
        </div>
      </div>
    </div>
  );

export default Related;

export const RelatedPropTypes = {
  title: PropTypes.string,
  related: PropTypes.arrayOf(
    PropTypes.shape(RelatedNewsPropTypes),
    PropTypes.shape(RelatedEventPropTypes),
    PropTypes.shape(RelatedPagePropTypes)
  ),
};

Related.propTypes = RelatedPropTypes;
