import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import PageBuilder from "../modules/page-builder";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helpers/text-serializer";
import ContentBlockLinkList from "../components/content-block-link-list";
import Header from "../modules/header";
import Related from "../modules/related";
import CapsLabel from "../elements/caps-label";

export const query = graphql`
  query ArticleTemplateQuery($id: String!) {
    article: sanityArticle(id: { eq: $id }) {
      title
      excerpt
      slug {
        current
      }
      publishDate
      heroTitle
      heroText
      taxonomy {
        tags {
          title
        }
        topic {
          title
          parentTopic
        }
      }
      related {
        ... on SanityArticle {
          title
          path
          _type
          articleType
          taxonomy {
            topic {
              title
            }
          }
        }
        ... on SanityPolicySubmission {
          title
          path
          _type
          articleType
          taxonomy {
            topic {
              title
            }
          }
        }
        ... on SanityEvent {
          _type
          title
          dateEnd
          dateStart
          eventType
          location
          path
        }
        ... on SanityPage {
          _type
          title
          excerpt
          path
        }
      }
      breadcrumbs {
        title
        slug
      }
      ...articleBuilderFields
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      seo {
        title
        description
        keywords
        image {
          asset {
            _id
            altText
          }
        }
      }
      sidebar {
        title
        links {
          type
          buttonText
          page {
            ... on SanityPage {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPagePhotoComp {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPageNews {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPageAlumni {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPagePolicy {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPageContact {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPageEvents {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPolicyArea {
              title
              slug {
                current
              }
              path
            }
            ... on SanityPolicySubmission {
              title
              slug {
                current
              }
              path
            }
            ... on SanityEvent {
              title
              slug {
                current
              }
              path
            }
            ... on SanityArticle {
              title
              slug {
                current
              }
              path
            }
          }
          file {
            title
            file {
              asset {
                url
                size
                extension
              }
            }
          }
          external {
            url
            title
          }
        }
      }
      taxonomy {
        topic {
          title
          parentTopic
        }
        tags {
          title
        }
      }
    }
  }
`;

const ArticleTemplate = props => {
  const { data } = props;
  const article = data.article;

  return (
    <Layout className="article">
      <SEO
        title={article.title}
        excerpt={article.excerpt}
        image={article.image}
        seo={article.seo}
        taxonomy={article.taxonomy}
      />
      <Header
        breadcrumbs={article.breadcrumbs}
        title={article.heroTitle || article.title}
        text={article.heroText}
        date={new Date(article.publishDate)}
        backgroundColour={"beige"}
        image={article.image}
      />
      {(article?._rawBody?.[0] || article?.sidebar?.[0]) && (
        <section className="m-article-body container">
          <div className="m-article-body-main">
            {article?._rawBody?.[0] && (
              <BlockContent
                className="m-body-content"
                blocks={article._rawBody}
                serializers={{
                  serializers,
                  types: {
                    block: serializers.types.block,
                    undefined: serializers.undefined,
                    blockVideo: serializers.blockVideo,
                    blockFigure: serializers.blockFigure,
                    pdfButton: serializers.pdfButton,
                  },
                  marks: {
                    link: serializers.marks.link,
                  },
                }}
                renderContainerOnSingleChild={true}
              />
            )}
          </div>

          <div className="m-article-body-sidebar">
            {article.sidebar?.[0] &&
              article.sidebar.map((sidebar, i) => (
                <ContentBlockLinkList
                  {...sidebar}
                  type={"background"}
                  key={i}
                />
              ))}
            {article?.taxonomy && (
              <div className="m-article-body-taxonomy">
                {article.taxonomy?.topic?.title &&
                  article.taxonomy?.topic?.parentTopic && (
                    <div className="m-article-body-taxonomy-row">
                      <h3 className="body-small">Topic</h3>
                      <CapsLabel
                        text={`${article.taxonomy.topic.parentTopic} / ${article.taxonomy?.topic.title}`}
                      />
                    </div>
                  )}

                {article.taxonomy?.tags?.[0] && (
                  <div className="m-article-body-taxonomy-row">
                    <h3 className="body-small">Tags</h3>
                    {article.taxonomy?.tags.map((tag, i) => {
                      return (
                        tag.title && <CapsLabel text={tag.title} key={i} />
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      )}
      <PageBuilder builder={article.pageBuilder} />
      {article.related?.[0] && <Related related={article.related} />}
    </Layout>
  );
};

ArticleTemplate.propTypes = {
  data: PropTypes.object,
};

export default ArticleTemplate;
